
import styled from "styled-components";
import { variablesGlobals } from "../../style/variables";

const SkillContainer = styled.div`
    width:fit-content;
    height:fit-content;
    aspect-ratio: 1;
    font-size: 0.6em;
    display:flex;
    padding:15px 20px;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    border:5px solid ${variablesGlobals.darkGreyBack};
    flex-wrap: nowrap;
    box-sizing:border-box;
    color: ${variablesGlobals.darkGreyBack};
    font-weight: 700;
    border-radius: 50%;
    background:radial-gradient(white, ${variablesGlobals.lightGreyBack});
    white-space: break-spaces;
    cursor:pointer;
    transition: filter 0.5s ease-in-out, font-size 0.5s ease-in-out;
    
    &:hover{
        filter:brightness(120%);
        font-size: 0.7em;
    }
    p{
        text-align: center;
        height:fit-content;
        margin:0 auto;
    }
`;

function OngletSkill({skill}){
    const {id = "", hardSkill = []} = skill||{};
    return <>
    <SkillContainer>
    {hardSkill.map((element, i)=> <p key={`s-${i}`}>{element}</p>)}
    </SkillContainer>
    
    
    </>


}

export default OngletSkill;