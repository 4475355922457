import styled from "styled-components";
import { variablesGlobals } from "../../style/variables";
import InfosBulle from "./InfosBulle";

const OngletContainer = styled.div`
   
`;

const OngletTitle = styled.div`
    display: flex;
    flex-direction: row;
    width:100%;
    box-sizing: border-box;
    flex-wrap: nowrap;
    cursor: pointer;
    padding:3px 50px;

`;

const OngletDescription = styled.div`
        padding:3px 20px;

`
function OngletExperience({experience}){
    
    const {id = "", date ="", role="", description="", text=""} = experience||{};
    return <>
    {experience
        &&<InfosBulle tooltipText={text}><OngletContainer>
        <OngletTitle>{date} {role}</OngletTitle>
        <OngletDescription>{description}</OngletDescription>
        </OngletContainer></InfosBulle>
        }
    </>
}

export default OngletExperience;