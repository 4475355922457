



export const variablesGlobals = {
fontFamilyLogo:"Baskerville Old Face, Georgia, serif",
fontFamilyWriting: "B612 Mono, monospace",
darkGreyBack:"#8998a7",
lightGreyBack:"#b3c0d2",
veryDarkGreyBack: "#3c576c"
}
;