import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import GetSceneContext from './SceneContext.jsx';

function SceneRef({ children }) {
    const sceneRef = useRef(new THREE.Scene());
    const cameraRef = useRef(new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000));
    const rendererRef = useRef(new THREE.WebGLRenderer({ antialias: true }));
    const containerRef = useRef(null);

    useEffect(() => {
        const renderer = rendererRef.current;
        const scene = sceneRef.current;
        const camera = cameraRef.current;
        const container = containerRef.current;
        const light = new THREE.AmbientLight(0xffffff, 1);
        light.position.set(1, 2, 4);
        scene.add(light);

        // Initialize the renderer
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.setSize(window.innerWidth, window.innerHeight);
        container.appendChild(renderer.domElement);

        // Setting the camera position a bit further back on the Z axis
        camera.position.set(0, 0, 0);

        // Handle window resizing
        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };
        window.addEventListener('resize', handleResize);

        // Mouse move event for controlling the camera
        const onMouseMove = (event) => {
            const xRotationFactor = 0.0002;
            const yRotationFactor = 0.0002;
            camera.rotation.x += event.movementY * xRotationFactor;
            camera.rotation.y += event.movementX * yRotationFactor;
        };
        document.addEventListener('mousemove', onMouseMove);

        // Continuous rotation based on the mouse position at the edges of the screen
        let zone = null;
        let animationFrameId; // To store the ID of the requestAnimationFrame

        const continuousRotation = () => {
            const rotationSpeed = 0.005;
            if (zone === "top") {
                camera.rotation.x -= rotationSpeed;
            } else if (zone === "bottom") {
                camera.rotation.x += rotationSpeed;
            } else if (zone === "left") {
                camera.rotation.y -= rotationSpeed;
            } else if (zone === "right") {
                camera.rotation.y += rotationSpeed;
            }
            animationFrameId = requestAnimationFrame(continuousRotation);
        };

        // Determine the 'zone' based on mouse position
        const onMouseMoveForZone = (event) => {
            const threshold = 30;
            if (event.clientY < threshold) {
                zone = "top";
            } else if (event.clientY > window.innerHeight - threshold) {
                zone = "bottom";
            } else if (event.clientX < threshold) {
                zone = "left";
            } else if (event.clientX > window.innerWidth - threshold) {
                zone = "right";
            } else {
                zone = null;
            }
        };
        document.addEventListener('mousemove', onMouseMoveForZone);
        
        // Start the continuous rotation
        continuousRotation();

        // Animation loop
        const animate = () => {
            renderer.render(scene, camera);
            animationFrameId = requestAnimationFrame(animate);
        };
        animate();

        // Cleanup function
        return () => {
            cancelAnimationFrame(animationFrameId); // Cancel the animation frame request
            renderer.dispose(); 
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mousemove', onMouseMoveForZone); 
            window.removeEventListener('resize', handleResize);
            if (container) {
                container.removeChild(renderer.domElement);
            }
        };
    }, []); 

    return (
        <GetSceneContext.Provider value={{ sceneContext: sceneRef.current, camera: cameraRef.current, renderer: rendererRef.current }}>
            <div ref={containerRef}>
                {children}
            </div>
        </GetSceneContext.Provider>
    );
}

export default SceneRef;
