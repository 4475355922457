
import styled from "styled-components";
import { diplome, experience, hardSkill, competence, softSkill, passion } from "./skillList";
import OngletSkill from "./OngletSkill";
import OngletCompetence from "./OngletCompetence";
import OngletDiplomes from "./OngletDiplomes";
import OngletExperience from "./OngletExperience";
import OngletPassion from "./OngletPassion";
import { variablesGlobals } from "../../style/variables";

const ContainerAboutStyle = styled.div`
    position:fixed;
    font-family: ${variablesGlobals.fontFamilyWriting};
    color: ${variablesGlobals.darkGreyBack};
    width:90%;
    padding:50px 150px;
    box-sizing: border-box;
    top:0;
    left:10%;
    height:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 100;
    overflow-y:auto;
    user-select: none;
    @media (min-width: 200px) and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        width:100%;
        left:0;
        top:200px;
        padding:50px 10px;
    }
    h2{
        padding:0 10px;
        background-color: #ffffff;
        border-radius: 15px;
        text-align: center;
        border:4px solid ${variablesGlobals.darkGreyBack};
        color:${variablesGlobals.darkGreyBack};
        font-size:larger;
        @media (min-width: 200px) and (max-width: 1200px) {
            font-size: medium;
            border:1px solid ${variablesGlobals.darkGreyBack};

        }
        
    }
    @media (min-width: 1200px) {

    &::-webkit-scrollbar {
      width: 10px; 
    
    }
  
    &::-webkit-scrollbar-track {
      background: #323d4213;
      
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: ${variablesGlobals.lightGreyBack}; 
      border-radius: 15px; 
      border: none; 
    }
  
    /* Firefox */
    scrollbar-width: auto; 
    scrollbar-color: #98a4bbca; }
`;

const ColonneOne = styled.div`
    display:flex;
    flex-direction: column;
    width: 30%;

    @media (min-width: 200px) and (max-width: 1200px) {
        width: 100%;

}
    & > div:first-child{
        @media (min-width: 200px) and (max-width: 1200px) {
        width: 100%;
            
    }

    }
    & > div:nth-child(2) {
        top:30px;
        height:fit-content;
        margin:50px 0;
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        & > div{
            display:flex;
            flex-direction: row;
            justify-content: flex-start;
            
        @media (min-width: 200px) and (max-width: 1200px) {
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
}
        }
    }
    & > div:nth-child(3){
            display:flex;
            width:fit-content;
            flex-direction: column;
            justify-content: flex-start;
            align-items:flex-start;
            @media (min-width: 200px) and (max-width: 1200px) {
               width:300px;

}
        }
`;
const ColonneTwo = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
    padding: 80px;
    position: relative;
    align-items: start; 
    justify-content: center;
    width: 40%;
    top: 300px;

    @media (min-width: 200px) and (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr); 
        top:0px;

}
@media (min-width: 1201px) and (max-width: 1700px) {
        grid-template-columns: repeat(2, 1fr); 
        top:600px;

}
    & > div:nth-child(odd) { 
        position: relative;
        left:20px;
        top:40px;
    }
    & > div:nth-child(even) {
    }
`;

;
const ColonneTree = styled.div`
    display:flex;
    flex-direction: column;
    @media (min-width: 200px) and (max-width: 1200px) {
        width:100%;

}
    & > div:last-child{
        height:500px;
 }

`
    ;



function ContainerAbout() {

    return <ContainerAboutStyle>
        <ColonneOne>

            <div>
                <h2>DIPLOMES</h2>
                {diplome.map((element, i) =>
                    <OngletDiplomes key={`diplome_${i}`} diplomePart={element}>
                    </OngletDiplomes>
                )}
            </div>

            <div>
                <h2>SOFT SKILLS</h2><div>
                    {softSkill.map((element, i) => (
                        <div key={`hard-skill_${i}`}>
                            <OngletSkill key={`hardSkill-skill_${i}`} skill={element} />
                        </div>
                    ))}</div>
            </div>
            <div>
                <h2>PASSIONS</h2>
                {passion.map((element, i) => (
                    <div key={`passion_${i}`}>
                        <OngletPassion key={`passionOnglet_${i}`} passion={element} />
                    </div>
                ))}
            </div>
        </ColonneOne>
        <ColonneTwo>
            <h2>MAITRISE</h2>
            {hardSkill.map((element, i) => (
                <div key={`hard-skill_${i}`}>
                    <OngletSkill skill={element} />
                </div>
            ))}
        </ColonneTwo>
        <ColonneTree>
            <div>
                <h2>COMPETENCES</h2>
                {competence.map((element, i) => <OngletCompetence key={`competence_${i}`} competence={element}></OngletCompetence>)}

            </div>
            <div>
                <h2>EXPERIENCES</h2>
                {experience.map((element, i) => <OngletExperience key={`experience_${i}`} experience={element}></OngletExperience>)}
            </div>
        </ColonneTree>
    </ContainerAboutStyle>


}

export default ContainerAbout;