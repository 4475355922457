export const diplome = [{
    id:"diplome-6", date: "2024-En cours", lieu:"OC",
    diplome: "Titre professionnel Niv7",
    description: "Datascientist, Machine Learning Engineer",
    text: "Techniques de statistique, apprentissage automatique et visualisation de données. Conception et developpement des modèles d'apprentissage automatique tout en assurant leur déploiement et leur maintenance."
},
{id:"diplome-5",date: "2022-2024", lieu:"OC",
    diplome: "Titre professionnel Niv6",
    description: "Concepteur, Developpeur de Logiciels :  Reactjs",
    text:"Elaboration d'architecture et de conception d'applications. Création, programmation, déploiement, test, documentation et maintenance de logiciels avec la spécialisation ReactJs"
},
{id:"diplome-4",date: "2020-2022", lieu:"UPHF",
    diplome: "Master",
    description: "Humanités Numériques :  User eXperience, Communications Numériques et Objets Connectés",
    text:"Interaction entre les technologies numériques et les disciplines des sciences humaines. Modélisation de base de données, statistique et algorithme avec une spécialisation User Experience (UX), conception de systèmes numériques autour de l'expérience utilisateur en prenant en compte son comportement, son objectif et ses besoins"
},
{id:"diplome-3",date: "2018-2020", lieu:"UPHF",
    diplome: "Master",
    description: "Arts et Management",
    text: "Connaissance des arts, de la culture et des institutions culturelles. Gestion de projets, droit, finance et marketing axés sur les évènement culturels. "
},
{id:"diplome-2",date: "2015-2018", lieu:"UPHF",
    diplome: "Licence",
    description: "Arts, Design Graphique et Design D'interaction",
    text:"Creation visuelle et conception interactive. Couvre le développement d'identités visuelles, supports de communication, conception d'experience numériques interactives. Connaissance accrue de l'histoire de l'art et du design."
},
{id:"diplome-1",date: "2014-2015", lieu:"UPHF",
    diplome: "DAEU",
    description: "A - Litterature",
    text:"Spécialité Literature, avec l'option économie"
}
];


export const experience = [,
    {id:"experience-3",date: "2024", role:"AutoEntrepreneur",
    description: "Linkleen : Programmation, Design, Conception" ,
    text:"Lancement de mon activité"
    },
    {id:"experience-2",date: "2019", role:"Bénévole",
    description: "Sytrileen: Management, Programmation, Design, Conception",
    text:"Soutien aux artistes, accompagnement, conseils, mentorat, mon rôle est principalement numérique"
    }, {id:"experience-1",date: "2008", role:"Artiste Numérique",
description: "Art, Musique, vidéo, 3D, Programmation, Conception",
    text:"partage, autonomie, initiative, autodidacte, patience, créativité, audace, résilience"
}
]

export const hardSkill = [
{id: "groupe2", hardSkill: ["Javascript", "Typescript", "NodeJs"]},
{id: "groupe1", hardSkill: ["Html/Css"]},
{id: "groupe10", hardSkill: ["UX Design", "Interaction", "Design Motion", "Design Responsive"]},
{id: "groupe8", hardSkill: ["Reactjs", "ReactNative", "Angular", "NextJs", "ThreeJs", "Express", "Django"]},
{id: "groupe3", hardSkill: ["Algorithme", "Python", "C#"]},
{id: "groupe4", hardSkill: ["Mysql", "Postgresql", "Firestore", "MongoDb"]},
{id: "groupe5", hardSkill: ["Adobe Suite", "Microsoft Office Suite", "VsCode", "JetBrain Suite"]},
{id: "groupe6", hardSkill: ["Docker", "Kubernetes", "Git", "GitHub", "GitLab", "Npm Package"]},
{id: "groupe7", hardSkill: ["Google Cloud Platfom", "Google Cloud Run", "Artifact Registry", "Firebase", "Authentication"]},
{id: "groupe9", hardSkill: ["Jest","Pytest","Documentation Technique","Jira","Trello","Kanban"]}
]

export const competence = [
    {id:"competence-1",
    description: "*Conception graphique et interaction",
    text:"Conception d'interfaces : administratives, applications internes, sites web, applications mobiles, en veillant à une cohérence visuelle et fonctionnelle pour chaque environnement et objectif."
    },
    {id:"competence-2",
    description: "*Gestion de projets",
    text:"Expérience des projets en situation professionnelle simulée : De l'évènementiel culturel jusqu'aux applications."
    },
    {id:"competence-3",
    description: "*Programmation en Javascript, Python, C#",
    text:"Les langages les plus populaires du monde numérique : JS pour le web, Python pour le Back et le Machine Learning, C# pour les jeux et l'automatisme, mais ces règles sont là pour être transgressées !"
    },
    {id:"competence-4",
    description: "*Développement d'interfaces avec React",
    text:"Le framework réactif du moment! Je travaille aussi avec Angular, React Native, et Django pour Python"
    },
    {id:"competence-5",
    description: "*Bases de données, API et algorithme",
    text:"Modèlisation de données, interface API, ORM, tous ces éléments sont essentiels à la puissance d'un logiciel"
    },
    {id:"competence-6",
    description: "*Visualisation de données",
    text:"Je travaille avec la bibliothèque D3 pour Js, Matplotlib, Seaborn, Plotly, Pandas pour Python"
    },
    {id:"competence-7",
    description: "*Techniques de machine learning",
    text:"En cours d'études, J'ai acquis les bases fondamentales de statistique, de modélisation de données et l'usage de principaux concepts de Machine Learning"
    },
];


export const softSkill = [{id: "softGroupe1", hardSkill: ["Esprit", "d’équipe"]},
{id: "softGroupe2", hardSkill: ["Veille", "Technologique"]},
{id: "softGroupe3", hardSkill: ["Sens", "du", "partage"]},
{id: "softGroupe4", hardSkill: ["Curiosité"]}
];

export const passion = [{id: "passionGroupe1", hardSkill: ["Musique"]},
{id: "passionGroupe2", hardSkill: ["Electronique"]},
{id: "passionGroupe3", hardSkill: ["Retrogame"]},
{id: "passionGroupe3", hardSkill: ["Ingenierie"]},
{id: "passionGroupe4", hardSkill: ["Peinture"]}
];
